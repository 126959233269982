import { useAppDispatch } from "../../constants/types";
import { closeLoginModal } from "../../store/loginModalSlice";

const LinksSection = () => {
  const dispatch = useAppDispatch();

  const handleLinkClick = () => {
    dispatch(closeLoginModal()); // 모달 닫기
  };

  return (
    <div className='text-sm text-right space-x-2'>
      <a
        href='/signup/agreement'
        onClick={handleLinkClick}
        className='text-hotduk hover:underline'>
        회원가입
      </a>
      <a
        href='/reset/verify'
        onClick={handleLinkClick}
        className='text-hotduk hover:underline'>
        ID/PW 찾기
      </a>
    </div>
  );
};

export default LinksSection;
