import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // 페이지 이동을 위해 필요합니다.
import Logo from "../../assets/logo.png"; // 로고 이미지의 경로를 실제 위치로 수정하세요.
import { getNiceStatus } from "../../apis/user";
import { useAppDispatch } from "../../constants/types";
import { openLoginModal } from "../../store/loginModalSlice";

interface CustomLocationState {
  fromSubmit?: boolean;
}

function SignupSuccess() {
  const navigate = useNavigate();
  const location = useLocation() as unknown as Location & {
    state?: CustomLocationState;
  };
  const dispatch = useAppDispatch();

  const handleLoginRedirect = () => {
    navigate("/");
    dispatch(openLoginModal());
  };

  useEffect(() => {
    if (!location.state?.fromSubmit) {
      navigate("/");
    }
  }, [location, navigate]);

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white p-8 rounded shadow-lg text-center max-w-md'>
        <img
          src='/assets/icon/10duk_newlogo_fin_02.svg'
          alt='10DUK 로고'
          className='mx-auto mb-6 w-24 h-24'
        />
        <h1 className='text-2xl font-bold mb-4'>축하합니다!</h1>
        <p className='text-gray-700 mb-6'>10DUK 가입이 완료되었어요.</p>
        <button
          onClick={handleLoginRedirect}
          className='px-6 py-2 bg-hotduk text-white rounded hover:bg-hotduk_light'>
          로그인 하기
        </button>
      </div>
    </div>
  );
}

export default SignupSuccess;
